@font-face {
  font-family: "san_fransisco_compact_text__regular";
  src: url("../fonts/san_fransisco_compact_text__regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "san_fransisco_compact_text__regular_italic";
  src: url("../fonts/san_fransisco_compact_text__regular_italic.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "san_fransisco_compact_text__medium";
  src: url("../fonts/san_fransisco_compact_text__medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "san_fransisco_compact_text__bold";
  src: url("../fonts/san_fransisco_compact_text__bold.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
:root {
  --ratio: 1.125;
  --fz-ratio-power--four: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--three: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--two: calc(1rem * var(--ratio) * var(--ratio));
  --fz-ratio-power--one: calc(1rem * var(--ratio));
  --fz-ratio-power--minus-one: calc(1rem / var(--ratio));
  --fz-ratio-power--minus-two: calc((1rem / var(--ratio)) / var(--ratio));
  --sp-ratio-power--four: calc(var(--fz-ratio-power--four) * 1.5);
  --sp-ratio-power--three: calc(var(--fz-ratio-power--three) * 1.5);
  --sp-ratio-power--two: calc(var(--fz-ratio-power--two) * 1.5);
  --sp-ratio-power--one: calc(var(--fz-ratio-power--one) * 1.5);
}
@media only screen and (min-width: 681px) {
  :root {
    --ratio: 1.1825;
  }
}
@media only screen and (min-width: 1281px) {
  :root {
    --ratio: 1.24;
  }
}
@-moz-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
html * {
  will-change: outline-offset;
  transition-property: outline-offset;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  outline-offset: 0;
}
html[data-focus-source="key"] *:focus,
html[data-focus-source="script"] *:focus {
  z-index: 100;
  outline-offset: 6px;
  outline: 2px dashed #ffb640;
  overflow: visible;
}
html[data-focus-source="pointer"] *:focus,
html[data-focus-source=""] *:focus {
  outline: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  word-wrap: break-word;
}
img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  vertical-align: bottom;
}
* + img {
  margin-top: 1.618rem;
}
svg {
  overflow: visible;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}
input,
button {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
}
button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font: inherit;
  color: inherit;
}
:root {
  font-size: 12px;
}
@media screen and (min-width: 480px) {
  :root {
    font-size: calc(0.5vw + 9.6px);
  }
}
@media screen and (min-width: 1280px) {
  :root {
    font-size: 16px;
  }
}
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
select,
input,
textarea {
  font-family: "san_fransisco_compact_text__regular", "sans-serif";
  font-weight: 400;
  line-height: 1.618;
  font-size: inherit;
  letter-spacing: -0.024em;
  color: #275682;
}
h1,
h2,
h3,
h4 {
  display: block;
  line-height: 1.5;
  font-family: "san_fransisco_compact_text__bold", "sans-serif";
  font-weight: 400;
  color: #0c2f50;
}
* + h1,
* + h2,
* + h3,
* + h4 {
  margin-top: 1.618rem;
}
h1 a,
h2 a,
h3 a,
h4 a,
h1 a:visited,
h2 a:visited,
h3 a:visited,
h4 a:visited {
  color: #6259ff;
}
h1 a:active,
h2 a:active,
h3 a:active,
h4 a:active,
h1 a:visited:active,
h2 a:visited:active,
h3 a:visited:active,
h4 a:visited:active,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h1 a:visited:hover,
h2 a:visited:hover,
h3 a:visited:hover,
h4 a:visited:hover,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h1 a:visited:focus,
h2 a:visited:focus,
h3 a:visited:focus,
h4 a:visited:focus {
  color: hsla(243,100%,63%,1);
}
h1,
h2 {
  letter-spacing: -0.026em;
}
h3,
h4 {
  letter-spacing: -0.036em;
}
h1 {
  max-width: 30ch;
  font-size: 1.601806640625rem;
  font-size: var(--fz-ratio-power--four);
}
@media only screen and (min-width: 681px) {
  h1 {
    font-size: 1.955260368789063rem;
    font-size: var(--fz-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  h1 {
    font-size: 2.36421376rem;
    font-size: var(--fz-ratio-power--four);
  }
}
.reading_content * + h1 {
  margin-top: 2.4027099609375rem;
  margin-top: var(--sp-ratio-power--four);
}
@media only screen and (min-width: 681px) {
  .reading_content * + h1 {
    margin-top: 2.932890553183595rem;
    margin-top: var(--sp-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  .reading_content * + h1 {
    margin-top: 3.54632064rem;
    margin-top: var(--sp-ratio-power--four);
  }
}
h2 {
  max-width: 30ch;
  font-size: 1.423828125rem;
  font-size: var(--fz-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  h2 {
    font-size: 1.653497140625001rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  h2 {
    font-size: 1.906624rem;
    font-size: var(--fz-ratio-power--three);
  }
}
.reading_content * + h2 {
  margin-top: 2.1357421875rem;
  margin-top: var(--sp-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  .reading_content * + h2 {
    margin-top: 2.480245710937501rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  .reading_content * + h2 {
    margin-top: 2.859936rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
h3 {
  max-width: 42ch;
  font-size: 1.265625rem;
  font-size: var(--fz-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  h3 {
    font-size: 1.39830625rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  h3 {
    font-size: 1.5376rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.reading_content * + h3 {
  margin-top: 1.8984375rem;
  margin-top: var(--sp-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  .reading_content * + h3 {
    margin-top: 2.097459375000001rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .reading_content * + h3 {
    margin-top: 2.3064rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
h4 {
  max-width: 50ch;
  font-size: 1.125rem;
  font-size: var(--fz-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  h4 {
    font-size: 1.1825rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  h4 {
    font-size: 1.24rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.reading_content * + h4 {
  margin-top: 1.6875rem;
  margin-top: var(--sp-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  .reading_content * + h4 {
    margin-top: 1.77375rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .reading_content * + h4 {
    margin-top: 1.86rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
p {
  width: auto;
  max-width: 100%;
  font-family: "san_fransisco_compact_text__regular", "sans-serif";
  font-size: 1rem;
  line-height: 1.618;
  letter-spacing: -0.024em;
  color: #275682;
}
* + p {
  margin-top: 1.618rem;
}
h1 + p,
h2 + p,
h3 + p {
  margin-top: 0.539333333333333rem;
}
h4 + p {
  margin-top: 0.809rem;
}
.reading_content p,
p.reading_content {
  width: 60ch;
}
.reading_content p > a,
p.reading_content > a,
.reading_content p > a:visited,
p.reading_content > a:visited {
  color: #6259ff;
}
.reading_content p > a:active,
p.reading_content > a:active,
.reading_content p > a:visited:active,
p.reading_content > a:visited:active,
.reading_content p > a:hover,
p.reading_content > a:hover,
.reading_content p > a:visited:hover,
p.reading_content > a:visited:hover,
.reading_content p > a:focus,
p.reading_content > a:focus,
.reading_content p > a:visited:focus,
p.reading_content > a:visited:focus {
  color: hsla(243,100%,63%,1);
}
a,
a:visited {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  text-decoration: none;
}
a:not([class]),
a:visited:not([class]),
a[class=""],
a:visited[class=""] {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #6259ff;
}
a:not([class]):active,
a:visited:not([class]):active,
a[class=""]:active,
a:visited[class=""]:active,
a:not([class]):focus,
a:visited:not([class]):focus,
a[class=""]:focus,
a:visited[class=""]:focus,
a:not([class]):hover,
a:visited:not([class]):hover,
a[class=""]:hover,
a:visited[class=""]:hover {
  color: hsla(243,100%,63%,1);
}
a:not([class]):active::after,
a:visited:not([class]):active::after,
a[class=""]:active::after,
a:visited[class=""]:active::after,
a:not([class]):focus::after,
a:visited:not([class]):focus::after,
a[class=""]:focus::after,
a:visited[class=""]:focus::after,
a:not([class]):hover::after,
a:visited:not([class]):hover::after,
a[class=""]:hover::after,
a:visited[class=""]:hover::after {
  transform: rotate(45deg);
}
a:not([class])::after,
a:visited:not([class])::after,
a[class=""]::after,
a:visited[class=""]::after {
  content: "↗";
  will-change: transform;
  transition-property: transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  vertical-align: super;
  display: inline-block;
  margin-left: 0.6ch;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio-power--minus-two);
}
@media only screen and (min-width: 681px) {
  a:not([class])::after,
  a:visited:not([class])::after,
  a[class=""]::after,
  a:visited[class=""]::after {
    font-size: 0.715150919192416rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  a:not([class])::after,
  a:visited:not([class])::after,
  a[class=""]::after,
  a:visited[class=""]::after {
    font-size: 0.650364203954214rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
b,
strong {
  font-weight: 400;
}
i,
em {
  font-style: italic;
}
::selection {
  background-color: #6259ff;
  text-shadow: 0 0 0.1em $cl--identity--dark;
  color: #fff;
}
.reading_content {
  width: 100%;
}
.reading_content ul,
.reading_content ol {
  display: block;
  width: 100%;
  max-width: 60ch;
  padding-left: 3ch;
}
.reading_content * + ul,
.reading_content * + ol {
  margin-top: 1.077588rem;
}
.reading_content ul {
  list-style-type: disc;
}
.reading_content ul ul {
  list-style-type: circle;
}
.reading_content ul ul ul {
  list-style-type: square;
}
.reading_content ol {
  list-style-type: decimal;
}
.reading_content ol ol {
  list-style-type: lower-alpha;
}
.reading_content ol ol ol {
  list-style-type: lower-roman;
}
.reading_content li {
  max-width: 100%;
}
.reading_content li ul:first-child,
.reading_content li ol:first-child {
  margin-top: 0.809rem;
}
.reading_content * + li {
  margin-top: 0.809rem;
}
.reading_content dl {
  color: #517ca4;
}
.reading_content dl dt,
.reading_content dl dd {
  display: block;
}
.reading_content dl dt {
  font-weight: 400;
}
* + .reading_content dl dt {
  margin-top: 1.077588rem;
}
.reading_content dl dt::after {
  content: ":";
}
* + .reading_content dl dd {
  margin-top: 0.539333333333333rem;
}
* + .reading_content dl dd {
  margin-left: 1.618rem;
}
sup {
  vertical-align: super;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 681px) {
  sup {
    font-size: 0.845665961945032rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  sup {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.global_header {
  z-index: 20;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  border-top: 1px solid #0883f7;
  background-image: url("../images/diagonal_stripes.svg");
  background-position: left top;
  background-repeat: repeat;
  background-size: 110% 110%;
}
@media only screen and (min-width: 881px) {
  .global_header {
    position: static;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    flex: 0 0 4.854rem;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    flex-direction: column;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    margin-top: 1.618rem;
    margin-bottom: 1.618rem;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    border-right: 1px solid #0883f7;
    border-bottom: 1px solid #0883f7;
    border-radius: 0 4px 4px 0;
  }
}
@media only screen and (min-width: 881px) {
  .navigation_is_open .global_header {
    border-radius: 0;
  }
}
.global_header__navigation_button {
  will-change: background-color;
  transition-property: background-color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #0883f7;
  background-color: #fff;
  padding: 0.809rem;
}
@media only screen and (min-width: 881px) {
  .global_header__navigation_button {
    border-right: 0;
    border-bottom: 1px solid #0883f7;
  }
}
@media only screen and (min-width: 881px) {
  .global_header__navigation_button {
    padding: 1.2135rem;
  }
}
.global_header__navigation_button:hover {
  background-color: hsla(243,100%,63%,1);
}
.global_header__navigation_button__vector {
  height: 1.7rem;
  width: 2.427rem;
  fill: #6259ff;
}
.global_header__navigation_button:hover .global_header__navigation_button__vector {
  fill: #fff;
}
.navigation_is_open .global_header__navigation_button__vector--open {
  display: none;
}
.global_header__navigation_button__vector--close {
  display: none;
  transform: scale(80%);
}
.navigation_is_open .global_header__navigation_button__vector--close {
  display: block;
}
.global_header__app_signifier {
  will-change: background-color;
  transition-property: background-color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  border-left: 1px solid #0883f7;
  background-color: #fff;
  padding: 0.809rem;
}
@media only screen and (min-width: 881px) {
  .global_header__app_signifier {
    border-top: 1px solid #0883f7;
  }
}
@media only screen and (min-width: 881px) {
  .global_header__app_signifier {
    border-left: 0;
  }
}
@media only screen and (min-width: 881px) {
  .global_header__app_signifier {
    padding: 1.2135rem;
  }
}
.global_header__app_signifier:focus,
.global_header__app_signifier:hover {
  background-color: hsla(243,100%,63%,1);
}
.global_header__app_signifier__vector {
  fill: #6259ff;
}
.global_header__app_signifier:active .global_header__app_signifier__vector,
.global_header__app_signifier:focus .global_header__app_signifier__vector,
.global_header__app_signifier:hover .global_header__app_signifier__vector {
  fill: #fff;
}
.global_header__app_signifier__vector--horizontal {
  display: block;
  height: 3.2rem;
  width: 6.472rem;
}
@media only screen and (min-width: 881px) {
  .global_header__app_signifier__vector--horizontal {
    display: none;
  }
}
.global_header__app_signifier__vector--vertical {
  display: none;
}
@media only screen and (min-width: 881px) {
  .global_header__app_signifier__vector--vertical {
    display: block;
    height: 4.9rem;
    width: 2.427rem;
  }
}
.global_navigation {
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  right: 0;
  bottom: 4.818000000000001rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid #0883f7;
  background-color: #f5faff;
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    left: 4.854rem;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    right: auto;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    bottom: 0;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    align-items: stretch;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    justify-content: flex-start;
    margin-top: 1.618rem;
    margin-bottom: 1.618rem;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    width: auto;
    border-top: 1px solid #0883f7;
    border-right: 1px solid #0883f7;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    border-radius: 0 4px 4px 0;
    background-image: url("../images/diagonal_stripes.svg");
    background-position: left -8px;
    background-repeat: repeat;
    background-size: 110% 110%;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation {
    background-color: transparent;
  }
}
.global_navigation[hidden] {
  display: none;
}
.global_navigation__list {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.077588rem;
}
@media only screen and (min-width: 881px) {
  .global_navigation__list {
    justify-content: flex-start;
    border-top: 1px solid #0883f7;
    border-bottom: 1px solid #0883f7;
    background-color: #fff;
  }
}
@media only screen and (min-width: 881px) {
  .global_navigation__list {
    margin-top: 4.127rem;
    margin-bottom: 7.327rem;
  }
}
.global_navigation__item {
  flex: 0 0 auto;
  width: 100%;
}
* + .global_navigation__item {
  border-top: 1px solid #66b2fa;
}
@media only screen and (min-width: 881px) {
  * + .global_navigation__item {
    border-top: 1px solid #66b2fa;
  }
}
.global_navigation__anchor {
  display: block;
  padding: 1.077588rem 0;
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #6259ff;
}
@media only screen and (max-width: 880px) {
  .global_navigation__anchor {
    font-size: 1.125rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.global_navigation__anchor:active,
.global_navigation__anchor:focus,
.global_navigation__anchor:hover {
  color: hsla(243,100%,63%,1);
}
.global_navigation__anchor:active::after,
.global_navigation__anchor:focus::after,
.global_navigation__anchor:hover::after {
  transform: rotate(45deg);
}
.global_navigation__anchor::after {
  content: "↗";
  will-change: transform;
  transition-property: transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  vertical-align: super;
  display: inline-block;
  margin-left: 0.6ch;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio-power--minus-two);
}
@media only screen and (min-width: 681px) {
  .global_navigation__anchor::after {
    font-size: 0.715150919192416rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .global_navigation__anchor::after {
    font-size: 0.650364203954214rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (max-width: 880px) and (min-width: 681px) {
  .global_navigation__anchor {
    font-size: 1.1825rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (max-width: 880px) and (min-width: 1281px) {
  .global_navigation__anchor {
    font-size: 1.24rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.action_anchor_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: -0.809rem;
}
* + .action_anchor_container {
  margin-top: 1.618rem;
}
.action_anchor {
  flex: 0 0 auto;
  will-change: color, border-color, box-shadow;
  transition-property: color, border-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0 0 0 #fff;
  border-bottom: 1px solid;
  padding: 0 0.539333333333333rem 0.269666666666667rem;
}
.action_anchor_container .action_anchor {
  margin-bottom: 0.809rem;
}
.action_anchor_container .action_anchor:not(:last-child) {
  margin-right: 0.809rem;
}
.action_anchor_container .action_anchor,
.button_container .action_anchor {
  margin-top: 0.3rem;
}
.action_anchor--primary_action {
  box-shadow: inset 0 0 0 #d1faf4;
  border-color: #14b89f;
  color: #0f8a77;
}
.action_anchor--primary_action:active,
.action_anchor--primary_action:focus,
.action_anchor--primary_action:hover {
  box-shadow: inset 0 -0.66em 0 #d1faf4;
  border-color: #0f8a77;
}
.action_anchor--primary_action .triangle__inner,
.action_anchor--primary_action .triangle__path {
  fill: #14b89f;
}
.action_anchor--secondary_action {
  box-shadow: inset 0 0 0 #d1faf4;
  border-color: #d1faf4;
  color: #0f8a77;
}
.action_anchor--secondary_action:active,
.action_anchor--secondary_action:focus,
.action_anchor--secondary_action:hover {
  box-shadow: inset 0 -0.66em 0 #d1faf4;
  border-color: #14b89f;
}
.action_anchor--secondary_action .triangle__inner {
  fill: #fff;
}
.action_anchor--secondary_action .triangle__path {
  fill: #14b89f;
}
.action_anchor--reductive_action {
  box-shadow: inset 0 0 0 #fff0d9;
  border-color: #fff0d9;
  color: #ffa20d;
}
.action_anchor--reductive_action:active,
.action_anchor--reductive_action:focus,
.action_anchor--reductive_action:hover {
  box-shadow: inset 0 -0.66em 0 #fff0d9;
  border-color: #ffb640;
}
.action_anchor--reductive_action .triangle__inner {
  fill: #fff;
}
.action_anchor--reductive_action .triangle__path {
  fill: #ffa20d;
}
.action_anchor--risk_action {
  box-shadow: inset 0 0 0 #fadce2;
  border-color: #e85573;
  color: #e85573;
}
.action_anchor--risk_action:active,
.action_anchor--risk_action:focus,
.action_anchor--risk_action:hover {
  box-shadow: inset 0 -0.66em 0 #fadce2;
  border-color: #bd1a3b;
  color: #bd1a3b;
}
.action_anchor--risk_action .triangle__inner {
  fill: transparent;
}
.action_anchor--risk_action .triangle__path {
  fill: #bd1a3b;
}
.action_anchor--disabled {
  cursor: not-allowed;
  border-color: #cccace;
  color: #b2afb6;
}
.action_anchor--disabled:active,
.action_anchor--disabled:focus,
.action_anchor--disabled:hover {
  cursor: not-allowed;
  box-shadow: inset 0 0 0 #fff;
  border-color: #cccace;
  color: #b2afb6;
}
.action_anchor--backwards_action:active .triangle,
.action_anchor--backwards_action:focus .triangle,
.action_anchor--backwards_action:hover .triangle {
  animation: action-anchor-interaction-backwards 0.28s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.action_anchor--forwards_action:active .triangle,
.action_anchor--forwards_action:focus .triangle,
.action_anchor--forwards_action:hover .triangle {
  animation: action-anchor-interaction-forwards 0.28s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.action_anchor--downwards_action:active .triangle,
.action_anchor--downwards_action:focus .triangle,
.action_anchor--downwards_action:hover .triangle {
  animation: action-anchor-interaction-downwards 0.28s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.action_anchor__text {
  flex: 0 0 auto;
  word-break: keep-all;
}
.action_anchor .triangle {
  flex: 0 0 auto;
}
.action_anchor .triangle--right,
.action_anchor .triangle--left {
  height: 0.5em;
  width: 0.33em;
}
.action_anchor .triangle--right,
.action_anchor .triangle--down {
  margin-left: 0.539333333333333rem;
}
.action_anchor .triangle--left {
  margin-right: 0.539333333333333rem;
}
.action_anchor .triangle--down {
  height: 0.44em;
  width: 0.5852em;
}
.application_container {
  will-change: unset;
  transform: none;
  filter: none;
  overflow: auto;
  display: block;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  background-color: #f5faff;
}
@media only screen and (min-width: 881px) {
  .application_container {
    overflow: hidden;
  }
}
@media only screen and (min-width: 881px) {
  .application_container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }
}
@media only screen and (min-width: 881px) {
  .application_container {
    max-height: 100vh;
  }
}
@media only screen and (max-width: 880px) {
  .application_container {
    padding-bottom: 4.818000000000001rem;
  }
}
.navigation_is_open .application_container {
  position: relative;
  z-index: auto;
}
.navigation_is_open .application_container::before {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: hsla(209.1,94%,78%,0.6);
}
.button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -0.809rem;
  width: 100%;
}
* + .button_container {
  margin-top: 1.618rem;
}
.button {
  will-change: color, background-color, border-color, box-shadow, text-shadow, transform;
  transition-property: color, background-color, border-color, box-shadow, text-shadow, transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  padding: 0.134833333333333rem 0.539333333333333rem 0.3236rem;
  word-break: keep-all;
}
.button_container .button {
  margin-bottom: 0.809rem;
}
.button_container .button:not(:last-child) {
  margin-right: 0.809rem;
}
.button--primary_action {
  box-shadow: inset 0 -2px 0 #0f8a77;
  border-color: #0f8a77;
  border-top-color: #14b89f;
  background-color: #14b89f;
  text-shadow: 0 0 2px #0f8a77;
  color: #fff;
}
.button--primary_action:active,
.button--primary_action:focus,
.button--primary_action:hover {
  box-shadow: inset 0 1px 2px #0f8a77;
  border-top-color: #0f8a77;
  background-color: #14b89f;
}
.button--secondary_action {
  box-shadow: inset 0 -2px 0 #d1faf4;
  border-color: #14b89f;
  background-color: #fff;
  color: #0f8a77;
}
.button--secondary_action:active,
.button--secondary_action:focus,
.button--secondary_action:hover {
  box-shadow: inset 0 1px 2px hsla(171,80%,70%,1);
  border-color: #0f8a77;
}
.button--reductive_action {
  box-shadow: inset 0 -2px 0 #fff0d9;
  color: #ffa20d;
}
.button--reductive_action border-color $cl--warn {
  background-color: #fff;
}
.button--reductive_action:active,
.button--reductive_action:focus,
.button--reductive_action:hover {
  box-shadow: inset 0 1px 2px hsla(37,100%,83%,1);
  border-color: #ffa20d;
  border-top-color: #ffb640;
}
.button--risk_action {
  box-shadow: inset 0 -2px 0 #bd1a3b;
  border-color: #bd1a3b;
  border-top-color: #e85573;
  background-color: #e85573;
  color: #fff;
}
.button--risk_action:active,
.button--risk_action:focus,
.button--risk_action:hover {
  box-shadow: inset 0 1px 2px #bd1a3b;
  border-top-color: #bd1a3b;
}
.button--disabled {
  box-shadow: inset 0 -2px 0 #cccace;
  border-color: #b2afb6;
  background-color: #f7f6f8;
  color: #b2afb6;
}
.button--disabled:active,
.button--disabled:focus,
.button--disabled:hover {
  transform: translateY(0);
}
.button--disabled:active,
.button--disabled:focus,
.button--disabled:hover {
  cursor: not-allowed;
}
.content_area {
  display: block;
  width: 92%;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}
* + .content_area {
  margin-top: 1.618rem;
}
.content_area__local_actions,
.content_area__content {
  display: block;
  box-shadow: 0 2px 0.2rem rgba(5,79,148,0.16);
  border: 1px solid #66b2fa;
  border-radius: 4px;
  background-color: #fff;
  padding: 1.618rem 4vw;
}
@media only screen and (min-width: 481px) {
  .content_area__local_actions,
  .content_area__content {
    padding: 1.618rem;
  }
}
.content_area__local_actions:not(:only-child) {
  border-bottom: 1px solid #66b2fa;
}
* + .content_area__content {
  margin-top: 1.618rem;
}
.data__summary {
  display: block;
  line-height: 1.5;
  font-family: "san_fransisco_compact_text__bold", "sans-serif";
  font-weight: 400;
  letter-spacing: -0.026em;
  color: #275682;
}
* + .data__summary {
  margin-top: 0.269666666666667rem;
}
.data__summary__arrow {
  color: #0883f7;
}
.data__list {
  display: block;
  width: 100%;
  max-width: 100%;
}
* + .data__list {
  margin-top: 1.077588rem;
}
.data__item {
  width: 100%;
  max-width: 100%;
  border: 1px solid #399cf9;
  border-radius: 4px;
}
* + .data__item {
  margin-top: 1.618rem;
}
.data__item__heading {
  padding: 0.4045rem 0.809rem;
}
.data__item__data_content {
  background-color: #0c2f50;
  padding: 0.809rem;
  text-shadow: 0 0 0.1em #0c2f50;
  color: #fff;
}
.data__item__data_definition__list {
  display: block;
  width: 100%;
}
.data__item__data_definition__item {
  display: flex;
  flex-direction: row;
}
* + .data__item__data_definition__item {
  border-top: 1px solid #399cf9;
}
.data__item__data_definition__term,
.data__item__data_definition__description {
  padding: 0.4045rem 0.809rem;
}
.data__item__data_definition__term {
  flex: 0 0 12rem;
}
.data__item__data_definition__description {
  flex: 1 1 0;
  border-left: 1px solid #399cf9;
}
.data__item__actions {
  border-top: 1px solid #399cf9;
  padding: 0.809rem;
}
* + .form {
  margin-top: 1.618rem;
}
.form *:not(button):required:invalid:focus,
.form *:not(button):required:invalid:active {
  border-color: $cl--error;
}
.form *:not(button):required:valid:focus,
.form *:not(button):required:valid:active {
  border-color: $cl--success;
}
.form__label {
  display: block;
  width: 100%;
  max-width: 60ch;
  color: #275682;
}
* + .form__label {
  margin-top: 1.618rem;
}
* + .form__field {
  margin-top: 0.539333333333333rem;
}
.form__field input,
.form__field textarea {
  will-change: border-color, box-shadow;
  transition-property: border-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  width: 60ch;
  max-width: 100%;
  box-shadow: inset 0 -3px 0 #e1f0fe;
  border-radius: 4px;
  border: 1px solid #66b2fa;
  border-left-width: 2px;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.form__field input::-webkit-input-placeholder,
.form__field textarea::-webkit-input-placeholder {
  color: #517ca4;
  opacity: 1;
}
.form__field input:-moz-placeholder,
.form__field textarea:-moz-placeholder {
  color: #517ca4;
  opacity: 1;
}
.form__field input::-moz-placeholder,
.form__field textarea::-moz-placeholder {
  color: #517ca4;
  opacity: 1;
}
.form__field input:-ms-input-placeholder,
.form__field textarea:-ms-input-placeholder {
  color: #517ca4;
  opacity: 1;
}
.form__field input:active,
.form__field textarea:active,
.form__field input:focus,
.form__field textarea:focus,
.form__field input:hover,
.form__field textarea:hover {
  outline: 0;
  box-shadow: inset 0 -1px 0 #e1f0fe;
  border-color: #399cf9;
}
.form__select_container {
  position: relative;
  z-index: auto;
  width: 100%;
  max-width: 60ch;
}
* + .form__select_container {
  margin-top: 0.539333333333333rem;
}
.form__select_container::before {
  content: "";
  transform: translateY(-50%);
  pointer-events: none;
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 0.809rem;
  height: 0.509rem;
  width: 0.809rem;
  background-image: url("../images/select_triangle.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.form__select_container select {
  will-change: border-color, box-shadow;
  transition-property: border-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  appearance: none;
  box-shadow: inset 0 -$bw 0 #e1f0fe;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #66b2fa;
  border-left-width: 2px;
  background-color: #fff;
  background-position: right 0.809rem top 50%;
  background-repeat: no-repeat;
  background-size: 1.2135rem;
  padding: 0.539333333333333rem 2.427rem 0.539333333333333rem 0.6472rem;
  padding-bottom: calc(0.539333333333333rem + 2px);
  color: #275682;
}
.form__select_container select:active,
.form__select_container select:focus,
.form__select_container select:hover {
  outline: 0;
  box-shadow: inset 0 -1px 0 #e1f0fe;
  border-color: #399cf9;
}
.form__checkbox {
  position: relative;
  z-index: auto;
  display: block;
}
.form__checkbox + .form__checkbox {
  margin-top: 0.539333333333333rem;
}
.form__checkbox__input {
  cursor: pointer;
  z-index: auto;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0;
}
.form__checkbox__label {
  cursor: pointer;
  position: relative;
  z-index: auto;
  display: inline-block;
  padding-left: 1.752833333333333rem;
}
.form__checkbox__label::before,
.form__checkbox__label::after {
  will-change: opacity, border-color, transform, zoom;
  transition-property: opacity, border-color, transform, zoom;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  content: "";
  position: absolute;
  display: inline-block;
}
.form__checkbox__label::before {
  top: 0.24rem;
  left: 0;
  height: 1.2135rem;
  width: 1.2135rem;
  border-radius: 4px;
  border: 1px solid #66b2fa;
  background-color: #fff;
}
.form__checkbox__input:focus + .form__checkbox__label::before {
  z-index: 100;
  outline-offset: 6px;
  outline: 2px dashed #ffb640;
  overflow: visible;
}
.form__checkbox__input:checked + .form__checkbox__label::before {
  border-color: #66b2fa;
}
.form__checkbox__label::after {
  z-index: 110;
  transform: rotate(-10deg) scale(0.4);
  top: 0.52rem;
  left: 0.2rem;
  height: 0.4045rem;
  width: 0.809rem;
  border-bottom: 2px solid #66b2fa;
  border-left: 2px solid #66b2fa;
}
.form__checkbox__input + .form__checkbox__label::after {
  opacity: 0;
}
.form__checkbox__input:checked + .form__checkbox__label::after {
  opacity: 1;
  transform: rotate(-45deg) scale(1);
}
.list_view__list {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
* + .list_view__list {
  margin-top: 1.618rem;
}
.list_view__item {
  will-change: transform, box-shadow;
  transition-property: transform, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 2px 2px 0 #399cf9;
  width: 100%;
  max-width: 700px;
  border: 1px solid #399cf9;
  border-radius: 4px;
  padding: 0.539333333333333rem;
}
* + .list_view__item {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 481px) {
  .list_view__item {
    padding: 1.077588rem;
  }
}
.list_view__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
}
.list_view__header > * {
  height: 100%;
}
.list_view__heading_and_priority_information {
  flex: 1 1 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
@media only screen and (min-width: 481px) {
  .list_view__heading_and_priority_information {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__heading_and_priority_information {
    justify-content: space-between;
  }
}
* + .list_view__heading_and_priority_information {
  margin-left: 0.539333333333333rem;
  border-left: 2px dashed #e1f0fe;
  padding-left: 0.539333333333333rem;
}
@media only screen and (min-width: 481px) {
  * + .list_view__heading_and_priority_information {
    margin-left: 1.077588rem;
  }
}
@media only screen and (min-width: 481px) {
  * + .list_view__heading_and_priority_information {
    padding-left: 1.077588rem;
  }
}
.list_view__heading {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
}
@media only screen and (min-width: 481px) {
  .list_view__heading {
    flex: 1 1 0;
  }
}
* + .list_view__heading {
  margin-top: 0;
}
.list_view__priority_information {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 481px) {
  .list_view__priority_information {
    flex: 0 0 auto;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__priority_information {
    flex-direction: column;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__priority_information {
    align-items: center;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__priority_information {
    margin-left: 1.077588rem;
    border-right: 2px dashed #e1f0fe;
    border-left: 2px dashed #e1f0fe;
    padding: 0 1.077588rem;
  }
}
@media only screen and (min-width: 681px) {
  .list_view__priority_information {
    font-size: 0.845665961945032rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .list_view__priority_information {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 481px) {
  .list_view__priority_information + .list_view__priority_information {
    border-left: 0;
    padding-left: 0;
  }
}
@media only screen and (max-width: 480px) {
  .list_view__priority_information:not(:last-child)::before {
    content: ",";
    display: inline-block;
    margin-right: 1ch;
  }
}
.list_view__priority_information__number {
  flex: 0 0 auto;
}
@media only screen and (min-width: 481px) {
  .list_view__priority_information__number {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.236rem;
    width: 3.236rem;
    font-size: 0.888888888888889rem;
    font-size: var(--fz-ratio-power--minus-one);
    color: #0c2f50;
  }
}
@media only screen and (min-width: 481px) and (min-width: 681px) {
  .list_view__priority_information__number {
    font-size: 0.845665961945032rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 481px) and (min-width: 1281px) {
  .list_view__priority_information__number {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 481px) and (min-width: 481px) {
  .list_view__priority_information__number {
    font-size: 1.423828125rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 481px) and (min-width: 481px) and (min-width: 681px) {
  .list_view__priority_information__number {
    font-size: 1.653497140625001rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 481px) and (min-width: 481px) and (min-width: 1281px) {
  .list_view__priority_information__number {
    font-size: 1.906624rem;
    font-size: var(--fz-ratio-power--three);
  }
}
.list_view__priority_information__label {
  flex: 0 0 auto;
  display: inline-block;
  text-align: center;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio-power--minus-one);
}
* + .list_view__priority_information__label {
  margin-top: 0.20225rem;
}
@media only screen and (min-width: 681px) {
  .list_view__priority_information__label {
    font-size: 0.845665961945032rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .list_view__priority_information__label {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (max-width: 480px) {
  .list_view__priority_information__label::after {
    content: ":";
    display: inline-block;
    margin-right: 0.5ch;
  }
}
.list_view__expand_button {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
* + .list_view__expand_button {
  margin-left: 0.539333333333333rem;
}
@media only screen and (min-width: 481px) {
  * + .list_view__expand_button {
    margin-left: 1.077588rem;
  }
}
.list_view__expand_button__vector {
  will-change: transform;
  transition-property: transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.236rem;
  width: 3.236rem;
  border-radius: 50%;
  border: 1px solid #6259ff;
  background-color: hsla(243,100%,68%,0.19999999999999996);
}
.list_view__item[open] .list_view__expand_button__vector {
  transform: rotate(180deg);
}
.list_view__expand_button__vector svg {
  flex: 0 0 auto;
  margin-top: 0.16rem;
  height: 1.077588rem;
  width: 1.3rem;
  fill: #6259ff;
}
.list_view__expand_button__label {
  flex: 0 0 auto;
  display: inline-block;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio-power--minus-one);
}
* + .list_view__expand_button__label {
  margin-top: 0.20225rem;
}
@media only screen and (min-width: 681px) {
  .list_view__expand_button__label {
    font-size: 0.845665961945032rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .list_view__expand_button__label {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.list_view__content {
  display: none;
  width: 100%;
}
* + .list_view__content {
  margin-top: 1.077588rem;
}
.list_view__item[open] .list_view__content {
  display: block;
}
.list_view__meta_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 1px 1px 0 #66b2fa;
  width: 100%;
  max-width: 100%;
  border: 1px solid #66b2fa;
  border-radius: 4px;
  padding: 0 0.809rem;
}
@media only screen and (min-width: 481px) {
  .list_view__meta_list {
    display: block;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__meta_list {
    padding: 0;
  }
}
.list_view__meta_item {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0.809rem 0;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 481px) {
  .list_view__meta_item {
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__meta_item {
    padding: 0;
  }
}
@media only screen and (min-width: 681px) {
  .list_view__meta_item {
    font-size: 0.845665961945032rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .list_view__meta_item {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
* + .list_view__meta_item {
  border-top: 1px solid #66b2fa;
}
.list_view__meta_term,
.list_view__meta_description {
  padding: 0;
  word-break: break-word;
  overflow-wrap: anywhere;
}
@media only screen and (min-width: 481px) {
  .list_view__meta_term,
  .list_view__meta_description {
    padding: 0.4045rem 0.539333333333333rem;
  }
}
.list_view__meta_term a,
.list_view__meta_description a,
.list_view__meta_term a:visited,
.list_view__meta_description a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #6259ff;
  font-weight: 400;
}
.list_view__meta_term a:active,
.list_view__meta_description a:active,
.list_view__meta_term a:visited:active,
.list_view__meta_description a:visited:active,
.list_view__meta_term a:focus,
.list_view__meta_description a:focus,
.list_view__meta_term a:visited:focus,
.list_view__meta_description a:visited:focus,
.list_view__meta_term a:hover,
.list_view__meta_description a:hover,
.list_view__meta_term a:visited:hover,
.list_view__meta_description a:visited:hover {
  color: hsla(243,100%,63%,1);
}
.list_view__meta_term a:active::after,
.list_view__meta_description a:active::after,
.list_view__meta_term a:visited:active::after,
.list_view__meta_description a:visited:active::after,
.list_view__meta_term a:focus::after,
.list_view__meta_description a:focus::after,
.list_view__meta_term a:visited:focus::after,
.list_view__meta_description a:visited:focus::after,
.list_view__meta_term a:hover::after,
.list_view__meta_description a:hover::after,
.list_view__meta_term a:visited:hover::after,
.list_view__meta_description a:visited:hover::after {
  transform: rotate(45deg);
}
.list_view__meta_term a::after,
.list_view__meta_description a::after,
.list_view__meta_term a:visited::after,
.list_view__meta_description a:visited::after {
  content: "↗";
  will-change: transform;
  transition-property: transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  vertical-align: super;
  display: inline-block;
  margin-left: 0.6ch;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio-power--minus-two);
}
@media only screen and (min-width: 681px) {
  .list_view__meta_term a::after,
  .list_view__meta_description a::after,
  .list_view__meta_term a:visited::after,
  .list_view__meta_description a:visited::after {
    font-size: 0.715150919192416rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .list_view__meta_term a::after,
  .list_view__meta_description a::after,
  .list_view__meta_term a:visited::after,
  .list_view__meta_description a:visited::after {
    font-size: 0.650364203954214rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.list_view__meta_term {
  flex: 0 0 auto;
  font-family: "san_fransisco_compact_text__medium", "sans-serif";
  color: #275682;
}
@media only screen and (min-width: 481px) {
  .list_view__meta_term {
    flex: 0 0 12rem;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__meta_term {
    padding-right: 1.077588rem;
  }
}
@media only screen and (max-width: 480px) {
  .list_view__meta_term {
    padding-bottom: 0;
  }
}
.list_view__meta_term::after {
  content: ":";
}
.list_view__meta_description {
  flex: 0 0 auto;
  color: #517ca4;
}
@media only screen and (min-width: 481px) {
  .list_view__meta_description {
    flex: 1 1 auto;
  }
}
@media only screen and (max-width: 480px) {
  * + .list_view__meta_description {
    margin-top: 0.4045rem;
  }
}
@media only screen and (min-width: 481px) {
  .list_view__meta_description {
    border-left: 1px solid #66b2fa;
  }
}
.list_view__count {
  margin-left: 0.25ch;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio-power--minus-two);
}
@media only screen and (min-width: 681px) {
  .list_view__count {
    font-size: 0.715150919192416rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .list_view__count {
    font-size: 0.650364203954214rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.local_header {
  width: 92%;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}
.local_header__page_summary {
  display: block;
  line-height: 1.5;
  font-family: "san_fransisco_compact_text__bold", "sans-serif";
  font-weight: 400;
  letter-spacing: -0.026em;
  font-size: 1.125rem;
  font-size: var(--fz-ratio-power--one);
  color: #275682;
}
* + .local_header__page_summary {
  margin-top: 0.4045rem;
}
@media only screen and (min-width: 681px) {
  .local_header__page_summary {
    font-size: 1.1825rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .local_header__page_summary {
    font-size: 1.24rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.local_header__page_summary__arrow {
  color: #0883f7;
}
.meta_list__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  border: 1px solid #399cf9;
  border-radius: 4px;
  padding: 0 0.809rem;
}
@media only screen and (min-width: 481px) {
  .meta_list__list {
    display: block;
  }
}
* + .meta_list__list {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 481px) {
  .meta_list__list {
    padding: 0;
  }
}
.meta_list__item {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0.809rem 0;
  font-size: 0.888888888888889rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 481px) {
  .meta_list__item {
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }
}
@media only screen and (min-width: 481px) {
  .meta_list__item {
    padding: 0;
  }
}
@media only screen and (min-width: 681px) {
  .meta_list__item {
    font-size: 0.845665961945032rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .meta_list__item {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
* + .meta_list__item {
  border-top: 1px solid #66b2fa;
}
.meta_list__term,
.meta_list__description {
  padding: 0;
  word-break: break-word;
  overflow-wrap: anywhere;
}
@media only screen and (min-width: 481px) {
  .meta_list__term,
  .meta_list__description {
    padding: 0.4045rem 0.539333333333333rem;
  }
}
.meta_list__term a,
.meta_list__description a,
.meta_list__term a:visited,
.meta_list__description a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #6259ff;
  font-weight: 400;
}
.meta_list__term a:active,
.meta_list__description a:active,
.meta_list__term a:visited:active,
.meta_list__description a:visited:active,
.meta_list__term a:focus,
.meta_list__description a:focus,
.meta_list__term a:visited:focus,
.meta_list__description a:visited:focus,
.meta_list__term a:hover,
.meta_list__description a:hover,
.meta_list__term a:visited:hover,
.meta_list__description a:visited:hover {
  color: hsla(243,100%,63%,1);
}
.meta_list__term a:active::after,
.meta_list__description a:active::after,
.meta_list__term a:visited:active::after,
.meta_list__description a:visited:active::after,
.meta_list__term a:focus::after,
.meta_list__description a:focus::after,
.meta_list__term a:visited:focus::after,
.meta_list__description a:visited:focus::after,
.meta_list__term a:hover::after,
.meta_list__description a:hover::after,
.meta_list__term a:visited:hover::after,
.meta_list__description a:visited:hover::after {
  transform: rotate(45deg);
}
.meta_list__term a::after,
.meta_list__description a::after,
.meta_list__term a:visited::after,
.meta_list__description a:visited::after {
  content: "↗";
  will-change: transform;
  transition-property: transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  vertical-align: super;
  display: inline-block;
  margin-left: 0.6ch;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio-power--minus-two);
}
@media only screen and (min-width: 681px) {
  .meta_list__term a::after,
  .meta_list__description a::after,
  .meta_list__term a:visited::after,
  .meta_list__description a:visited::after {
    font-size: 0.715150919192416rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .meta_list__term a::after,
  .meta_list__description a::after,
  .meta_list__term a:visited::after,
  .meta_list__description a:visited::after {
    font-size: 0.650364203954214rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.meta_list__term {
  flex: 0 0 auto;
  font-family: "san_fransisco_compact_text__medium", "sans-serif";
  color: #275682;
}
@media only screen and (min-width: 481px) {
  .meta_list__term {
    flex: 0 0 12rem;
  }
}
@media only screen and (min-width: 481px) {
  .meta_list__term {
    padding-right: 1.077588rem;
  }
}
@media only screen and (max-width: 480px) {
  .meta_list__term {
    padding-bottom: 0;
  }
}
.meta_list__term::after {
  content: ":";
}
.meta_list__description {
  flex: 0 0 auto;
  color: #517ca4;
}
@media only screen and (min-width: 481px) {
  .meta_list__description {
    flex: 1 1 auto;
  }
}
@media only screen and (max-width: 480px) {
  * + .meta_list__description {
    margin-top: 0.4045rem;
  }
}
@media only screen and (min-width: 481px) {
  .meta_list__description {
    border-left: 1px solid #66b2fa;
  }
}
.meta_list__count {
  margin-left: 0.25ch;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio-power--minus-two);
}
@media only screen and (min-width: 681px) {
  .meta_list__count {
    font-size: 0.715150919192416rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .meta_list__count {
    font-size: 0.650364203954214rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.pagination {
  display: block;
  margin-top: 3.236rem;
  margin-bottom: -1.618rem;
  width: 100%;
}
.pagination__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.pagination__item {
  flex: 0 0 auto;
  margin-bottom: 1.618rem;
}
.pagination__item:first-child {
  margin-right: 0.4045rem;
}
.pagination__item:not(:first-child):not(:last-child) {
  margin-right: 0.4045rem;
  margin-left: 0.4045rem;
}
.pagination__item:last-child {
  margin-left: 0.4045rem;
}
.pagination__anchor {
  will-change: background-color, box-shadow, color;
  transition-property: background-color, box-shadow, color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 #6259ff;
  border: 1px solid #6259ff;
  border-radius: 2px;
  height: 2.427rem;
  width: 2.427rem;
  color: #6259ff;
}
.pagination__anchor:active,
.pagination__anchor:focus,
.pagination__anchor:hover {
  box-shadow: 2px 2px 0 hsla(243,100%,63%,1);
  color: hsla(243,100%,63%,1);
}
.pagination__anchor--current_page,
.pagination__anchor--current_page:active,
.pagination__anchor--current_page:focus,
.pagination__anchor--current_page:hover {
  box-shadow: none;
  border: 1px solid $cl--interface;
  border-radius: 50%;
  background-color: $cl--interface--light;
  color: #517ca4;
}
.pagination__anchor--arrow {
  box-shadow: none;
  border: 0;
  border-radius: 50%;
  background-color: #6259ff;
}
.pagination__anchor--arrow:active,
.pagination__anchor--arrow:focus,
.pagination__anchor--arrow:hover {
  box-shadow: none;
  background-color: hsla(243,100%,63%,1);
}
.pagination__anchor--arrow svg {
  height: 1.618rem;
  width: 1.618rem;
  fill: #fff;
}
.table_wrap {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  border: 1px solid #399cf9;
  border-radius: 4px;
}
* + .table_wrap {
  margin-top: 0.809rem;
}
table {
  display: table;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0;
  width: fit-content;
  width: 100%;
  max-width: 100%;
}
table th,
table td {
  min-width: 180px;
  border-right: 1px solid #399cf9;
  border-bottom: 1px solid #399cf9;
  padding: 0.809rem;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}
table th {
  text-align: left;
  font-family: "san_fransisco_compact_text__medium", "sans-serif";
}
table thead {
  color: #0c2f50;
}
table thead th {
  vertical-align: bottom;
  background-color: #e1f0fe;
  text-shadow: 0 0 0.1em #fff;
}
table tbody {
  min-width: 100%;
  color: #275682;
}
table tbody tr:last-child td {
  border-bottom: 0;
}
table tbody th {
  vertical-align: top;
  font-family: 400;
}
table tbody td:nth-child(even) {
  background: #fff;
}
table a,
table a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #6259ff;
}
table a:active,
table a:visited:active,
table a:focus,
table a:visited:focus,
table a:hover,
table a:visited:hover {
  color: hsla(243,100%,63%,1);
}
table a:active::after,
table a:visited:active::after,
table a:focus::after,
table a:visited:focus::after,
table a:hover::after,
table a:visited:hover::after {
  transform: rotate(45deg);
}
table a::after,
table a:visited::after {
  content: "↗";
  will-change: transform;
  transition-property: transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  vertical-align: super;
  display: inline-block;
  margin-left: 0.6ch;
  font-size: 0.790123456790123rem;
  font-size: var(--fz-ratio-power--minus-two);
}
@media only screen and (min-width: 681px) {
  table a::after,
  table a:visited::after {
    font-size: 0.715150919192416rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  table a::after,
  table a:visited::after {
    font-size: 0.650364203954214rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.view_content {
  flex: 1 1 0;
  display: block;
  overflow-y: auto;
}
.view_content > *:first-child {
  margin-top: 1.618rem;
}
.view_content > *:last-child {
  margin-bottom: 1.618rem;
}
.reference_colour {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
* + .reference_colour {
  margin-top: 1.618rem;
}
* + .reference_colour {
  border-top: 2px solid #66b2fa;
  padding-top: 1.618rem;
}
.reference_colour__colour {
  position: relative;
  z-index: auto;
  flex: 0 0 31%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #054f94;
  border-radius: 4px;
}
.reference_colour__colour--identity {
  background-color: #6259ff;
}
.reference_colour__colour--interface-light--high {
  background-color: #e1f0fe;
}
.reference_colour__colour--interface-light {
  background-color: #66b2fa;
}
.reference_colour__colour--interface-light--low {
  background-color: #399cf9;
}
.reference_colour__colour--interface-dark--high {
  background-color: #0883f7;
}
.reference_colour__colour--interface-dark {
  background-color: #0669c6;
}
.reference_colour__colour--interface-dark--low {
  background-color: #054f94;
}
.reference_colour__colour--interface-background {
  background-color: #f5faff;
}
.reference_colour__colour--interface-divider {
  background-color: #66b2fa;
}
.reference_colour__colour--interface-disabled {
  background-color: hsla(209.1,94%,78%,0.6);
}
.reference_colour__colour--risk--high {
  background-color: #fadce2;
}
.reference_colour__colour--risk {
  background-color: #e85573;
}
.reference_colour__colour--risk--low {
  background-color: #bd1a3b;
}
.reference_colour__colour--warn--high {
  background-color: #fff0d9;
}
.reference_colour__colour--warn {
  background-color: #ffb640;
}
.reference_colour__colour--warn--low {
  background-color: #ffa20d;
}
.reference_colour__colour--forward--high {
  background-color: #d1faf4;
}
.reference_colour__colour--forward {
  background-color: #14b89f;
}
.reference_colour__colour--forward--low {
  background-color: #0f8a77;
}
.reference_colour__colour--disabled--high {
  background-color: #f7f6f8;
}
.reference_colour__colour--disabled {
  background-color: #cccace;
}
.reference_colour__colour--disabled--low {
  background-color: #b2afb6;
}
.reference_colour__colour--text--heading {
  background-color: #0c2f50;
}
.reference_colour__colour--text--copy {
  background-color: #275682;
}
.reference_colour__colour--text--metadata {
  background-color: #517ca4;
}
.reference_colour__colour--anchor {
  background-color: #6259ff;
}
.reference_colour__colour--anchor--interact {
  background-color: hsla(243,100%,63%,1);
}
.reference_colour__colour--interface-light--high .reference_colour__colour__name,
.reference_colour__colour--interface-light .reference_colour__colour__name,
.reference_colour__colour--interface-light--low .reference_colour__colour__name,
.reference_colour__colour--interface-background .reference_colour__colour__name,
.reference_colour__colour--interface-divider .reference_colour__colour__name,
.reference_colour__colour--interface-disabled .reference_colour__colour__name,
.reference_colour__colour--risk--high .reference_colour__colour__name,
.reference_colour__colour--warn--high .reference_colour__colour__name,
.reference_colour__colour--forward--high .reference_colour__colour__name,
.reference_colour__colour--forward .reference_colour__colour__name,
.reference_colour__colour--disabled--high .reference_colour__colour__name,
.reference_colour__colour--disabled .reference_colour__colour__name,
.reference_colour__colour--disabled--low .reference_colour__colour__name {
  border-color: #517ca4;
  color: #275682;
}
.reference_colour__colour:nth-child(3n+2),
.reference_colour__colour:nth-child(3n+3) {
  margin-left: 3.5%;
}
.reference_colour__colour:nth-child(n+4) {
  margin-top: 3.5%;
}
.reference_colour__colour::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.reference_colour__colour__name {
  flex: 0 1 auto;
  margin: 1.077588rem;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 0.269666666666667rem 0.539333333333333rem;
  color: #fff;
}
